.App {
  text-align: center;
  flex-direction: column;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Message{
  min-width: 100vw;
  min-height: 70vh;
  display: flex;
  flex-direction: row;
}

.user-name{
  width: 330px;
  height: 35px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 4px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}
 
.textarea {
  width: 330px;
  height: 150px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 4px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

.box{
  float: left;
  width: 50%;
  padding: 50px;
}

.post-btn {
  color: black;
}

.all-post{
  display: flex;
  width: 100%;
  height: 220px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 4px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
